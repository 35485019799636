/**
 * Permet de créer une trappe de focus sur un élément, en empêchant la navigation hors de cet élément avec la touche Tab.
 * @param element L'élement sur lequel la trappe de focus doit être appliquée.
 * @param shouldPreventScroll Détermine si la page doit empêcher le window scroll lors de la navigation dans la trappe de focus.
 * @param onOutOfFocusCallback s'il y a un callback, la tabulation va quitter le popup
 */
export function trapFocusOn(
  element: HTMLElement,
  shouldPreventScroll: boolean,
  onOutOfFocusCallback?: () => void
) {
  const focusableElements = element.querySelectorAll(
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
  );
  const firstFocusableElement = focusableElements[0] as HTMLElement;
  const lastFocusableElement = focusableElements[
    focusableElements.length - 1
  ] as HTMLElement;

  element.addEventListener("keydown", handleFocus);

  const negativeTabs = element.querySelectorAll('[tabindex="-1"]');
  let content: HTMLElement;
  if (negativeTabs.length > 0) {
    content = negativeTabs[0] as HTMLElement;
    content.focus({ preventScroll: true });
  } else {
    firstFocusableElement.focus({ preventScroll: shouldPreventScroll });
  }

  function handleFocus(e: any) {
    const isTabPressed = e.key === "Tab";
    if (!isTabPressed) {
      return;
    }

    if (document.activeElement === content) {
      e.preventDefault();
      if (e.shiftKey) {
        lastFocusableElement.focus();
      } else {
        firstFocusableElement.focus();
      }
    } else if (e.shiftKey && document.activeElement === firstFocusableElement) {
      e.preventDefault();
      onOutOfFocusCallback != null
        ? onOutOfFocusCallback()
        : lastFocusableElement.focus();
    } else if (document.activeElement === lastFocusableElement) {
      e.preventDefault();
      onOutOfFocusCallback != null
        ? onOutOfFocusCallback()
        : firstFocusableElement.focus();
    }
  }
}
